import * as React from "react"
import styled from 'styled-components'
import Layout from "../components/layout"
import Seo from "../components/seo"

const StyledHeader = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <StyledHeader>
    <h1>404: Not Found</h1>
    </StyledHeader>
  </Layout>
)

export default NotFoundPage
